<template>
  <div class="outer">
    <div class="imgOne">
      <img :src="imgList.zhutu" alt="" />
    </div>
    <div class="outerTwo">
      <div class="textOne">{{ $t("recruitment.RT_textOne") }}</div>
      <div class="textTwo">{{ $t("recruitment.RT_textTwo") }}</div>

      <div class="modular">
        <div
          v-for="(item, index) in $t('recruitment.RT_modular')"
          :key="index"
          class="modularItem"
          @click="modularItem(item)"
        >
          <!--  :style="'backgroundImage:url(' + item.bjImg + ')'" -->
          <div class="itemCont">
            <div>{{ item.name }}</div>
            <img src="@/assets/img/checkbai.png" alt="" />
          </div>
          <img :src="item.bjImg" alt="" class="bjImg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：人才招聘
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-13 10:44
 */
export default {
  data() {
    return {
      imgList: "",
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.getBigPicture();
  },
  methods: {
    /**
     * 模块跳转
     * 刘嘉鑫
     * 2022-8-15
     */
    modularItem(item) {
      this.$router.push({
        path: item.path,
      });
    },

    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-19
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgCenter",
        data: {
          type: 3, // 类型:1=行业服务,2=行业科普,3=人才招聘,4=关于我们,5=技术研发
        },
      }).then((res) => {
        console.log("大图", res);
        this.imgList = res.img_list;
        this.$t("recruitment.RT_modular")[0].bjImg = res.img_list.renczc;
        this.$t("recruitment.RT_modular")[1].bjImg = res.img_list.renczp;
        this.$t("recruitment.RT_modular")[2].bjImg = res.img_list.xincfl;
        this.$t("recruitment.RT_modular")[3].bjImg = res.img_list.gongzhj;
        this.$t("recruitment.RT_modular")[4].bjImg = res.img_list.yuanggs;
        this.$t("recruitment.RT_modular")[5].bjImg = res.img_list.renclh;
      });
    },
    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-8-23
     */
    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll >= this.$(".modular").offset().top - 800) {
        this.$(".modularItem").addClass("showDiv");
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 260px 60px;
}
.imgOne {
  width: 100%;
  height: 600px;
  margin-bottom: 79px;
  > img {
    width: 100%;
    height: 100%;
  }
}

.outerTwo {
  padding: 0 52px;
}

.textOne {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: 400;
  color: #1a2a60;
  margin-bottom: 59px;
}

.textTwo {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #444444;
  line-height: 32px;
  margin-bottom: 79px;
}

.modular {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.modularItem {
  width: 30%;
  height: 180px;
  margin-bottom: 60px;
  overflow: hidden;
  transition: all 0.8s ease-out 0s;
  transform: translate(-100px, 0px);
  opacity: 0;
  position: relative;
  cursor: pointer;

  .bjImg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all 0.9s !important;
  }
}

.modularItem:hover {
  > .bjImg {
    transform: scale(1.2) !important;
  }
}

.modularItem:nth-child(1),
.modularItem:nth-child(4) {
  transition-delay: 0.2s;
}

.modularItem:nth-child(2),
.modularItem:nth-child(5) {
  transition-delay: 0.4s;
}

.modularItem:nth-child(3),
.modularItem:nth-child(6) {
  transition-delay: 0.6s;
}

.itemCont {
  padding: 48px 40px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 59%;
}

.itemCont div {
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.itemCont img {
  width: 20px;
  height: 14px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.modularItem:hover {
  > .itemCont {
    img {
      animation: checkImg 0.7s linear infinite;
      position: relative;
    }
  }
}
</style>